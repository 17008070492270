import {
    Avatar,
    Button,
    Col,
    Divider,
    Drawer,
    Form,
    Input,
    List,
    Row,
    Select,
    Upload,
    message,
    Card
  } from "antd";
  import React, { useEffect, useState} from "react";
  import {
    EditOutlined,
    BookOutlined
  } from "@ant-design/icons";
  import TextArea from "antd/lib/input/TextArea";
  import ChapterList from "./ChapterList";
  import { CreateAssessment, CreateTopic , GetCurriculum, GetExercises, UpdateTopic, lessonMaterialsUpload, topicVideoUpload} from "../../redux/actions/CurriculumAction";
  import { useDispatch } from "react-redux";
  import Assessment from "./Assesment";
  import { useParams } from "react-router-dom";
import AddUpdateExercise from "./AddUpdateExercise";

  
  const EditTopic = ({ curriculumId, moduleId,  topicData, chapterId, curriculum}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
  
    const [open, setOpen] = useState(false);
    const [selectedChapter, setSelectedChapter] = useState();
    const [selectedType, setSelectedType] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [assessmentData, setAssessmentData] = useState([]);
    // const [lessonMaterialURL, setLessonMaterialURL] = useState();
    const [videoURL, setVideoURL] = useState();
    const [loader, setLoader] = useState(false)
    const [isUploadDisabled, setIsUploadDisabled] = useState(false);
    const [videoLoader, setVideoLoader] = useState(false)
    const [isVideoDisabled, setIsVideoDisabled] = useState(false);
    const [learner15MinMaterial, setLearner15MinMaterial] = useState();
    const [learner25MinMaterial, setLearner25MinMaterial] = useState();
    const [tutor15MinMaterial, setTutor15MinMaterial] = useState();
    const [tutor25MinMaterial, setTutor25MinMaterial] = useState();
    const [isValid, setIsValid] = useState(true);
    const [topicMaterial, setTopicMaterial] = useState("");
    const [topicsId, setTopicsId] = useState([]);
    const [exercises, setExercises] = useState([]);
    const { id } = useParams();

    const getExercises = () => {
      const onSuccess = (data) => {
        setExercises(data);
       };
       const onError = (msg) => {
         message.error(msg)
       };
       GetExercises(topicData._id, onSuccess, onError); 
    }

    useEffect(() => {
      if(topicData && open){
        console.log("mylogrender", open, id, topicData);
        getExercises();
      }
    }, [open, topicData]);

    useEffect(() => {
        if (topicData) {
          form.setFieldsValue({
            name: topicData.name,
            description: topicData.description,
            type: topicData.type,
            curriculumId: topicData.curriculumId,
            moduleId: topicData.moduleId,
            chapterId: topicData.chapterId,
            thumbnail: topicData.thumbnail
          });
        const currentModule = curriculum.modules.find(module=>module._id===moduleId);
        const matchedTopic = currentModule?.topics?.find(topic => topic._id === topicData._id);
          form.setFieldsValue({
            learner15MinMaterial: matchedTopic?.sessionMaterial?.learner15MinMaterial || '',
            learner25MinMaterial: matchedTopic?.sessionMaterial?.learner25MinMaterial || '',
            tutor15MinMaterial: matchedTopic?.sessionMaterial?.tutor15MinMaterial || '',
            tutor25MinMaterial: matchedTopic?.sessionMaterial?.tutor25MinMaterial || '',
          })
        }
      }, [topicData, topicsId]);
  
    const handleNotesChange = (e) => {
      const fieldName = e.target.name;
    
      const isValidPdfLink = /\.pdf$/.test(e.target.value.toLowerCase());
    
      switch (fieldName) {
        case "learner15MinMaterial":
          setLearner15MinMaterial(e.target.value);
          break;
        case "learner25MinMaterial":
          setLearner25MinMaterial(e.target.value);
          break;
        case "tutor15MinMaterial":
          setTutor15MinMaterial(e.target.value);
          break;
        case "tutor25MinMaterial":
          setTutor25MinMaterial(e.target.value);
          break;
        default:
          break;
      }
    
      setIsValid(isValidPdfLink);
    };
    
    
  
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
  
    const handleAssessmentChange = (data) => {
      setAssessmentData(data);
    };
  
  
      // selected the file to upload
      // const handleFileSelect = (e) => {
      //   const file = e.target.files[0];
  
      //   if (file) {
      //     setSelectedFile(file);
      //     setIsUploadDisabled(false); 
      //   }
      // };
  
      // selected the video to upload
      const handleVideoSelect = (e) => {
        const file = e.target.files[0];
  
        if (file) {
          setSelectedVideo(file);
          setIsVideoDisabled(false); 
        }
      };
    
    
     // Using fetch to get the presigned URL and upload the file to S3
      // const handleUpload = () => {
      //   setLoader(true)
      //     const data = {
      //       fileName: selectedFile?.name,
      //       fileType: selectedFile?.type,
      //     };
    
      //     const onSuccess = async (res) => {
      //       const presignedURL = res.data?.data?.presignedurl;
      //       setLessonMaterialURL(res.data?.data?.img_url)
    
      //       const response = await fetch(presignedURL, {
      //         method: 'PUT',
      //         body: selectedFile,
      //         headers: {
      //           'Content-Type': selectedFile.type,
      //         },
      //       });
    
      //       if (response.ok) {
      //         message.success('File uploaded successfully!');
      //         setLoader(false)
      //         setIsUploadDisabled(true)
      //       } else {
      //         message.error('Failed to upload file:', response.statusText);
      //       }
      //     };
    
      //     const onError = (errmsg) => {
      //       message.error(errmsg);
      //       setLoader(false)
      //     };
      //     lessonMaterialsUpload(data, onSuccess, onError);
        
      // };
    
  
       // Using fetch to get the presigned URL and upload the file to S3
       const handleVideoUpload = () => {
        setVideoLoader(true)
          const data = {
            fileName: selectedVideo?.name,
            fileType: selectedVideo?.type,
          };
    
          const onSuccess = async (res) => {
            const presignedURL = res.data?.data?.presignedurl;
            setVideoURL(res.data?.data?.img_url)
    
            const response = await fetch(presignedURL, {
              method: 'PUT',
              body: selectedVideo,
              headers: {
                'Content-Type': selectedVideo.type,
              },
            });
    
            if (response.ok) {
              message.success('File uploaded successfully!');
              setVideoLoader(false)
              setIsVideoDisabled(true)
            } else {
              message.error('Failed to upload file:', response.statusText);
            }
          };
    
          const onError = (errmsg) => {
            message.error(errmsg);
            setVideoLoader(false)
          };
          topicVideoUpload(data, onSuccess, onError);
        
      };
  
    const createTopic = async () => {
      try {
        if (!isValid) {
          message.error('Please add notes links with .pdf extension.');
          return;
        }
        const {
          learner15MinMaterial,
          learner25MinMaterial,
          tutor15MinMaterial,
          tutor25MinMaterial,
          ...topicValues
        } = await form.validateFields();
    
        topicValues["curriculumId"] = curriculumId;
        topicValues["moduleId"] = moduleId;
        topicValues["chapterId"] = chapterId;
        topicValues['videoUrl'] = videoURL;
        topicValues['topicId'] = topicData?._id;
    
        const lessonMaterial = {
          learner15MinMaterial,
          learner25MinMaterial,
          tutor15MinMaterial,
          tutor25MinMaterial
        };
        topicValues['lessonMaterial'] = lessonMaterial;
  
        console.log(topicValues);
  
        const onSuccessCreateTopic = async (res) => {
          message.success("Topic updated successfully :)");
          setOpen(false);
    
          if (selectedType === "Assessment") {
            const assessmentPayload = 
              {
                type: "Multiple Choice",
                questionsData: assessmentData,
                topicId: res.topic._id,
              }
  
            const onSuccessCreateAssessment = () => {
              console.log("Assessment updated successfully");
            };
  
            const onErrorCreateAssessment = () => {
              console.log("Error creating assessment");
            };
  
            await CreateAssessment(
              assessmentPayload,
              onSuccessCreateAssessment,
              onErrorCreateAssessment,
              dispatch
            );
          }
        };
        const onErrorCreateTopic = (msg) => {
          message.error(msg);
        };
  
        UpdateTopic(topicValues, onSuccessCreateTopic, onErrorCreateTopic, dispatch);
      } catch (errorInfo) {
        console.log("Validation Failed:", errorInfo);
      }
    };
  
    return (
      <>
        <Button type="dashed" onClick={showDrawer} block icon={<EditOutlined />}>
           Edit
        </Button>
        <Drawer
          title="Add Topic"
          width={"40%"}
          closable={false}
          onClose={onClose}
          visible={open}
        >
          <Form form={form} layout="vertical">
            {/* <Form.Item
              label={`Serial Number`}
              name={`serialNo`}
              rules={[
                { required: true, message: "Please add a serial number!" },
                
              ]}
            >
              <Input placeholder="Serial Number" />
            </Form.Item> */}
            <Form.Item
              label={`Topic Title`}
              name="name"
              rules={[{ required: true, message: "Please add topic title !" }]}
            >
              <Input placeholder="Topic title" />
            </Form.Item>
            <Form.Item
              label="Topic Description"
              name="description"
              rules={[
                { required: true, message: "Please add topic description !" },
              ]}
            >
              <TextArea rows={2} />
            </Form.Item>
            
            <Form.Item
            label="Thumbnail"
            name="thumbnail"
            >
              <Input placeholder="Topic thumbnail" />
            </Form.Item>

            <Row justify="space-between">
              {/* <Col span={11}>
                <Form.Item
                  label="Select Chapter"
                  name="chapterId"
                  // rules={[
                  //   { required: true, message: "Please select a chapter !" },
                  // ]}
                >
                  <ChapterList
                    curriculumId={curriculumId}
                    module={module}
                    setChapter={setSelectedChapter}
                  />
                </Form.Item>
              </Col> */}
              <Col span={11}>
                <Form.Item
                  label="Select Type"
                  name="type"
                  rules={[{ required: true, message: "Please topic type" }]}
                >
                  <Select onChange={(value) => setSelectedType(value)}>
                    <Select.Option value="Session">Session</Select.Option>
                    {chapterId ? 
                      <>
                        <Select.Option value="Assessment">Assessment</Select.Option>
                        <Select.Option value="Buddy Session">
                          Buddy Session
                        </Select.Option>
                      </>
                      :
                      <Select.Option value="Group Session">Group Session</Select.Option>
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
  
            {selectedType === "Assessment" ? (
              <Assessment onAssessmentChange={handleAssessmentChange} />
            ) : (
              <>
              {/* <div className="upload" style={{marginBottom:"0.5rem"}}>Upload Learning Material</div>
              <Input type="file" onChange={handleFileSelect}/>
              <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button type="primary" block onClick={() => handleUpload()} loading={loader} disabled={!selectedFile || isUploadDisabled}>
              {loader? "Uploading" : "Upload"}
              </Button>
            </div> */}
             <Form.Item
              label={`Learner 15 Min Note`}
              name="learner15MinMaterial"
  
              rules={[{ required: true, message: "Please add learner note !" }]}
  
            >
              <Input placeholder="Learner's 15 Min Note" onChange={handleNotesChange} type="text" />
            </Form.Item>
  
            <Form.Item
              label={`Learner 25 Min Note`}
              name="learner25MinMaterial"
  
              rules={[{ required: true, message: "Please add learner note !" }]}
  
            >
              <Input placeholder="Learner's 25 Min Note" onChange={handleNotesChange} type="text" />
            </Form.Item>
  
            <Form.Item
              label={`Tutor 15 Min Note`}
              name="tutor15MinMaterial"
  
              rules={[{ required: true, message: "Please add tutor note !" }]}
  
            >
              <Input placeholder="Tutor's 15 Min Note" onChange={handleNotesChange} type="text" />
            </Form.Item>
  
            
            <Form.Item
              label={`Tutor 25 Min Note`}
              name="tutor25MinMaterial"
  
              rules={[{ required: true, message: "Please add tutor note !" }]}
  
            >
              <Input placeholder="Tutor's 25 Min Note" onChange={handleNotesChange} type="text" />
            </Form.Item>
            </>
            )}

            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button type="primary" block onClick={() => createTopic()}>
                Edit Topic
              </Button>
            </div>

            <Divider orientation="left">Exercises</Divider>

            <Card
              style={{ padding: 0}} // This might also be necessary
              bodyStyle={{ padding: 0 }}
            >
           {exercises.length > 0 ?  <List
                itemLayout="horizontal"
                dataSource={exercises}
                renderItem={(exercise, index) => (
                  <List.Item
                    style={{paddingLeft: '8px'}}
                    actions={
                      [
                        <AddUpdateExercise exerciseData={exercise} exercises={exercises} setExercises={setExercises} />,
                      ]
                    } 
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          shape="square"
                          icon={
                            exercise.type==="reading" ?
                            <i class="fab fa-readme"></i> :
                            exercise.type==="listening" ?
                            <i class="fas fa-assistive-listening-systems" />
                            : exercise.type==="conversational" ?
                            <i class="fas fa-comments"></i>
                            : null
                          }
                        />
                      }
                      style={{alignItems: 'center'}}
                      title={exercise?.type}
                    />
                  </List.Item>
                )}
              /> : null}

              {exercises.length < 3 ? <AddUpdateExercise exercises={exercises} setExercises={setExercises} topicData={topicData} /> : null}

              </Card>

              {/* <div style={{marginBottom:"0.5rem", marginTop:"0.5rem"}}>Upload Topic Video</div>
              <Input type="file"  onChange={handleVideoSelect}/>
              <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button type="primary" block onClick={() => handleVideoUpload()} loading={videoLoader} disabled={!selectedVideo || isVideoDisabled}>{videoLoader? "Uploading" : "Upload"}
              </Button>
            </div>
  
            <div style={{marginBottom:"0.8rem", marginTop:"0.8", fontSize:"0.8rem"}}>Note: Please click on upload topic video button before adding the topic.</div> */}
  
          </Form>
        </Drawer>
      </>
    );
  };
  
  export default EditTopic;
  