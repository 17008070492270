import api from "../apis/PlanApi";
import * as constant from "../constants/PlanConstants";

export const FetchAllPlan = () => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .FetchAll()
    .then((res) => {
      dispatch({
        type: constant.FETCH_ALL_PLANS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        console.log("Timeout");
      }else{
      console.log(err.response);
      }
    });
};

export const FetchAllPlanIELTS = (subject) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .FetchAllIELTS(subject)
    .then((res) => {
      console.log(res)
      dispatch({
        type: constant.FETCH_ALL_PLANS_IELTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const FetchAllPlanKids = (subject) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .FetchAllKids(subject)
    .then((res) => {
      console.log(res)
      dispatch({
        type: constant.FETCH_ALL_PLANS_KIDS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const FetchTransaction = (onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .FetchTransaction()
    .then((res) => {
      console.log(res);
      if (onSuccess) {
        onSuccess(res.data);
      }
      dispatch({
        type: constant.FETCH_TRANSACTION,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (onError) {
        if (err.code === 'ECONNABORTED'){
          onError("Timeout");
        }else{
        console.log(err.response);
        onError(err.response.data.message);
        }
      }
      console.log(err.response);
    });
};

export const ApplyCuponCode = (data, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .CuponCode(data)
    .then((res) => {
      console.log(res);
      if (res.data.statusCode === 500) {
        onError(res);
      } else {
        onSuccess(res.data.influencers);
      }
    })
    .catch((err) => {
      console.log(err);
      if (onError) {
        if (err.code === 'ECONNABORTED'){
          onError("Timeout");
        }else{
        console.log(err.response);
        onError(err.response.data.message);
        }
      }
    });
};

export const subscribeAPlan = (data, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .Subscribe(data)
    .then((res) => {
      console.log(res);
      onSuccess();
      //   dispatch({
      //       type: constant.FETCH_ALL_PLANS,
      //       payload: res.data
      //   })
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        onError("Timeout");
      }else{
      console.log(err.response);
      onError(err.response.data.message);
      }
    });
};
export const currentPlan = (data, onSuccess) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .currentPlan(data)
    .then((res) => {
      console.log(res);
      onSuccess(res);
      // dispatch({
      //   type: constant.CURRENT_PLAN,
      //   payload: res.data,
      // });
     
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        console.log("Timeout");
      }else{
      console.log(err.response);
      }
    });
};

export const upgradePlan = (data, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .upgrade(data)
    .then((res) => {
      console.log(res);
      onSuccess();
      //   dispatch({
      //       type: constant.FETCH_ALL_PLANS,
      //       payload: res.data
      //   })
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        onError("Timeout");
      }else{
      console.log(err.response);
      onError(err.response.data.message);
      }
    });
};

export const PausePlan = (data, onSuccess, onError) => (dispatch) => {
   console.log(data)
  api
    .Plan()
    .PausePlan(data)
    .then((res) => {
      // console.log(res)
      onSuccess(res.data.message)
        // dispatch({
        //     type: constant.UPDATE_STATUS,
        //     payload: data
        // })
      
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        onError("Timeout");
      }else{
      console.log(err.response);
      onError(err.response.data.message);
      }
    });
};

export const ActivateNext = (data, onSuccess, onError) => (dispatch) => {
  console.log(data)
 api
   .Plan()
   .ActivateNext(data)
   .then((res) => {
     console.log(res)
     onSuccess((res) =>{
       console.log(res)
     });
       // dispatch({
       //     type: constant.UPDATE_STATUS,
       //     payload: data
       // })
     
   })
   .catch((err) => {
    if (err.code === 'ECONNABORTED'){
      onError("Timeout");
    }else{
    console.log(err.response);
    onError(err.response.data.message);
    }
   });
};

export const ResumePlan = (data, onSuccess, onError) => (dispatch) => {
  // SIGNUP LEARNER
  api
    .Plan()
    .ResumePlan(data)
    .then((res) => {
      onSuccess(res.data.message)
//   dispatch({
//     type: constant.UPDATE_STATUS,
//     payload: data
// })
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        onError("Timeout");
      }else{
      console.log(err.response);
      onError(err.response.data.message);
      }
    });
};

export const getPlanIELTS = (onSuccess,planParam) => (dispatch) => {
  //SIGNUP LEARNER
  console.log(planParam)
  api
    .Plan()
    .getplanIELTS(planParam)
    .then((res) => {
      console.log('plan data',res);
      onSuccess(res.data);
        // dispatch({
        //     type: constant.FETCH_ALL_PLANS,
        //     payload: res.data
        // })
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const getPlan = (onSuccess) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .getplan()
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
      //   dispatch({
      //       type: constant.FETCH_ALL_PLANS,
      //       payload: res.data
      //   })
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        console.log("Timeout");
      }else{
      console.log(err.response);
      }
    });
};

export const getAllCourse = (onSuccess) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .getAllCourse()
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
      //   dispatch({
      //       type: constant.FETCH_ALL_PLANS,
      //       payload: res.data
      //   })
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        console.log("Timeout");
      }else{
      console.log(err.response);
      }
    });
};

export const GeneratePaymentLink = (data,onSuccess,onError) => (dispatch) => {
  api
    .Plan()
    .generatePaymentLink(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        onError("Timeout");
      }else{
      console.log(err.response);
      onError(err.response);
      }
    });
};



export const getplanHomepage = (onSuccess) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .getplanHomepage()
    .then((res) => {
      console.log('plan data',res.data);
      onSuccess(res.data);
      //   dispatch({
      //       type: constant.FETCH_ALL_PLANS,
      //       payload: res.data
      //   })
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        console.log("Timeout");
      }else{
      console.log(err.response);
      }
    });
};

export const FetchAllHomepage = () => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Plan()
    .FetchAllHomepage()
    .then((res) => {
      console.log(res)
      dispatch({
        type: constant.FETCH_HOMEPAGE_PLANS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        console.log("Timeout");
      }else{
      console.log(err.response);
      }
    });
};

//capture stripe payment
export const CaptureStripePayment = (data,onSuccess,onError) => (dispatch) => {
  console.log("api triggred")
  api
    .Plan()
    .StripeCapture(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        onError("Timeout");
      }else{
      console.log(err.response);
      onError(err.response);
      }
    });
};



export const UpdateGlobalRequirement = (data) => (dispatch) => {
  dispatch({
    type: constant.GLOBAL_UPDATE,
    payload: data,
  });
};
