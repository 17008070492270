import React,{useState, useEffect} from "react";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { Card, Col , DatePicker, Select, Spin, Table, TimePicker, message} from "antd"; 
import SessionCard from "../../component/learnerComponent/SessionCard";
import { AvailableTeacher } from "../../redux/actions/SessionAction";
import { GetAllVideoSessions, GetUserVideoSessions, learnerProfile } from "../../redux/actions/UserAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import moment from "moment-timezone";
import { currentPlan } from "../../redux/actions/PlanAction";
import VideosCard from "../../component/learnerComponent/VideosCard";
import { formatPopulateVideoSessionData } from "../../helper/helper";
const { Option } = Select;


const ScheduleSession = () => {

  const timeSlots ={
    "thirtyMin": [
      "00:00 - 00:30",
      "00:30 - 01:00",
      "01:00 - 01:30",
      "01:30 - 02:00",
      "02:00 - 02:30",
      "02:30 - 03:00",
      "03:00 - 03:30",
      "03:30 - 04:00",
      "04:30 - 05:00",
      "05:00 - 05:30",
      "05:30 - 06:00",
      "06:00 - 06:30",
      "06:30 - 07:00",
      "07:00 - 07:30",
      "07:30 - 08:00",
      "08:00 - 08:30",
      "08:30 - 09:00",
      "09:00 - 09:30",
      "09:30 - 10:00",
      "10:00 - 10:30",
      "10:30 - 11:00",
      "11:00 - 11:30",
      "11:30 - 12:00",
      "12:00 - 12:30",
      "12:30 - 13:00",
      "13:00 - 13:30",
      "13:30 - 14:00",
      "14:00 - 14:30",
      "14:30 - 15:00",
      "15:00 - 15:30",
      "15:30 - 16:00",
      "16:00 - 16:30",
      "16:30 - 17:00",
      "17:00 - 17:30",
      "17:30 - 18:00",
      "18:00 - 18:30",
      "18:30 - 19:00",
      "19:00 - 19:30",
      "19:30 - 20:00",
      "20:00 - 20:30",
      "20:30 - 21:00",
      "21:00 - 21:30",
      "21:30 - 22:00",
      "22:00 - 22:30",
      "22:30 - 23:00",
      "23:00 - 23:30",
      "23:30 - 00:00",
    ],
    "OneHr": [
        "00 - 01",
        "01 - 02",
        "02 - 03",
        "03 - 04",
        "04 - 05",
        "05 - 06",
        "06 - 07",
        "07 - 08",
        "08 - 09",
        "09 - 10",
        "10 - 11",
        "11 - 12",
        "12 - 13",
        "13 - 14",
        "14 - 15",
        "15 - 16",
        "16 - 17",
        "17 - 18",
        "18 - 19",
        "19 - 20",
        "20 - 21",
        "21 - 22",
        "22 - 23",
        "23 - 24"
      ]
    }

 
    const gridContainerStyle = {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap:"2rem"
    };

  const [learner,setLearner] = useState(null)
  const [teacherList, setTeacherList] = useState([]);
  const [teacherSessions, setTeacherSessions] = useState([]);
  const [teachersLoaded, setTeachersLoaded] = useState(true);
  const [subject, setSubject] = useState(null)
  const [selectedDate, setSelectedDate] = useState("")
  const [selectedTime, setSelectedTime] = useState("")
  const dispatch = useDispatch();
  let { id } = useParams();
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  const [classDuration, setClassDuration] = useState("")
  const [currentSubscriptions, setCurrentSubscriptions] = useState([{}])
  const [currentKidSubscriptions, setCurrentKidSubscriptions] = useState([{}])
  const [IELTScurrentSubscriptions, setIELTSCurrentSubscriptions] = useState([{}])
  const [allSubscriptions, setAllSubscriptions] = useState(null);
  const [IELTSSubscriptions, setIELTSSubscriptions] = useState(null);
  const [allVideos, setAllVideos] = useState([]);
  const [videoSession, setVideoSessions] = useState({});
  const [formattedVideoSessions, setFormattedVideoSessions] = useState([]);  
  const timings = teacherList.map((data)=> data?.timings)
  const bookedTeacherId =teacherSessions.map((teacher) => teacher.teacher._id);
  const bookedStartTiming =teacherSessions.map((timing) => timing?.startTime);
  const bookedEndTiming =teacherSessions.map((timing) => timing?.endTime);

console.log("IELTSSSUBs", subject)
  const startTiming = timings.map(innerArray => {
    const startTime = innerArray.map(value => value.startTime);
    return startTime
  });


  const endTiming = timings.map(innerArray => {
    const endTime = innerArray.map(value => value.endTime);
    return endTime
  });

  // Financial Education videos
  // useEffect(()=> {
  //   const params = {
  //     subject : "Financial Education"
  //   }
  //   const onSuccess = (data) => {
  //     setAllVideos(data?.videoSessionsDetail)
  //   }
  //   const onError = (error) =>{
  //     message.error(error)
  //   }
  //  dispatch(GetAllVideoSessions(params, onSuccess, onError))
  // }, [subject === "Financial Education"])
  
  useEffect(() => {
      const onSuccess = (res) => {
        // console.log("CURRENTPLAN", res.data.subscriptions);
        setAllSubscriptions(res.data.subscriptions);
        setCurrentSubscriptions(res.data.currentsubscriptions)
        setClassDuration(currentSubscriptions[0]?.plan.classDuration)
      };

      const onIELTSSuccess = (res) => {
        // console.log("CURRENTPLAN", res.data.subscriptions);
        setIELTSSubscriptions(res.data.subscriptions);
        setIELTSCurrentSubscriptions(res.data.currentsubscriptions)
        setClassDuration(currentSubscriptions[0]?.plan.classDuration)
      };
      dispatch(currentPlan({ learnerId: id }, onSuccess));
      dispatch(currentPlan({ learnerId:id, subject: "IELTS" }, onIELTSSuccess))

  }, [learner, selectedTime, selectedDate, subject, learner?.learner?.isKid === false]);


  useEffect(() => {
    const onSuccess = (res) => {
      setAllSubscriptions(res.data.subscriptions);
      setCurrentKidSubscriptions(res.data.purchasedSessionData)
    };
    dispatch(currentPlan({ learnerId: id , subject:"English"}, onSuccess));

}, [learner, selectedTime, selectedDate, subject, learner?.learner?.isKid === true]);
  useEffect(() => {
    const onSuccess = (res) => {
      setAllSubscriptions(res.data.subscriptions);
      setCurrentKidSubscriptions(res.data.purchasedSessionData)
    };
    dispatch(currentPlan({ learnerId: id , subject:"English"}, onSuccess));

}, [learner, selectedTime, selectedDate, subject, learner?.learner?.isKid === true]);
  useEffect(() => {
    
  const data = {
    learnerId:id,
  }

  const onSuccess = (res) =>{
    setLearner(res)
  }

  const onError = (err) =>{
    console.log(err)
  }

  dispatch(learnerProfile(data,onSuccess,onError))
}, []);

  useEffect(() => {
      setTeachersLoaded(true)
      var getTeacherPayload = {
        date: selectedDate,
        timeInterval: selectedTime,
        isDemo: false,
        timezone: time_zone.timezone,
        isYoutubeFiltered: false,
        subject: subject,
        forKids:  learner?.learner?.isKid === true ? true : false
      };

      const onSuccess = (res) => {
        setTeacherList(res.teachers)
        setTeacherSessions(res.sessions)
        setTeachersLoaded(false)
      }

      const onError = (err) => {
        setTeachersLoaded(false)
      }

      AvailableTeacher(getTeacherPayload, onSuccess, onError)
  }, [subject ,selectedDate, selectedTime]);

  // Get the current date and time
  const currentDate = moment().format("YYYY-MM-DD");
  const currentTime = moment().format("HH - mm");
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  useEffect(() => {
    // Filter time slots based on the current time
    // const filteredTimeSlots30 = timeSlots.thirtyMin.filter((timeSlot) => {
    //   const [startHour] = timeSlot.split(" - ");
    //   const [currentHour, currentMinute] = currentTime.split(" - ");
    //   return parseInt(startHour, 10) >= parseInt(currentHour, 10);
    // });

    const isToday = moment(selectedDate).isSame(currentDate, 'day');
    const filteredTimeSlots1 = isToday? timeSlots.OneHr.filter((timeSlot) => {
      const [startHour] = timeSlot.split(" - ");
      const [currentHour, currentMinute] = currentTime.split(" - ");
      return parseInt(startHour, 10) >= parseInt(currentHour, 10);
    }) : timeSlots.OneHr
      setSelectedTimeSlots(filteredTimeSlots1);
  }, [currentTime, selectedDate, subject]);


  // Get unlocked video 
  // useEffect(() =>{
  //     const bookedVertical = learner?.learner?.demoSessionBooked?.filter(
  //       (sub) => sub.subjects === "Financial Education"
  //     )
  //     const payload = {
  //       learnerId: learner?.learner?._id,
  //       subject: "Financial Education",
  //       isDemo: bookedVertical?.length === 0 ? true : false,
  //     };
  //     const onSuccess = (data) => {
  //       const filteredData = !data?.videoSessionDetails?.isDeleted
  //         ? data.videoSessionDetails
  //         : {};
  //       if (filteredData) setVideoSessions(filteredData);
  //     };
  //     const onError = (err) => {
  //       console.error("Error while fetching video sessions");
  //       setVideoSessions({});
  //     }
  //     dispatch(GetUserVideoSessions(payload, onSuccess, onError));
  // },[learner?.learner])


// check booked and locked state
  // useEffect(() => {
  //   if (
  //     allVideos?.length > 0
  //   ) {
  //     let allVideoSessionCopy = [...allVideos];
  //     let matchFound = false;
  //     allVideoSessionCopy.forEach((session) => {
  //       if (session._id === videoSession._id) {
  //         matchFound = true;
  //         session.teacherId = videoSession.teacherId;
  //       }else if (matchFound === true) session.isLocked = true; 
  //       if (!matchFound) {
  //         session.isBooked = true;
  //       }
  //     });
  //     setFormattedVideoSessions(allVideoSessionCopy);
  //   }
  // }, [videoSession]);
  

    return (
        <div>
            <PageBreadcrumb route={["dashboard", "Schedule Session"]} />
            <Card title= "Schedule Session">
               <h1 style={{fontSize:"1.5rem"}}>Pick a slot </h1>
                <div style={{display:"flex", flexDirection:"row" , gap:"2rem"}}>
                <div>
                <h3>Select Date:</h3>
                <DatePicker onChange={(date, dateString) => setSelectedDate(dateString)}/>
                </div>
                <div>
                    <h3>Select Time:</h3>
                    <Select value={selectedTime} style={{ width: 120 }} onChange={(value) => setSelectedTime(value)} disabled={selectedDate === "" || selectedDate < currentDate}>
                    {selectedTimeSlots.map((time, i) => (
                <Option key={i} value={time}>
                  {time}
                </Option>
              ))}

                  </Select>
                </div>
                <div>
                    <h3>Select Subject:</h3>
                    <Select style={{ width: 200 }} value={subject} onChange={(value) => setSubject(value)}>
                      {learner?.learner?.isKid === true ?
                      <>
                      <Option key={1} disabled>Select Subject</Option>
                      <Option key={"English"}>English</Option>
                      </>
                       :
                       <>
                      {learner?.learner?.activeSubscription?.map((data, i) => (
                        <Option key={i} value={data.subjects}>
                          {data.subjects}
                        </Option>
                      ))}
                      </>
                       }
                    </Select>
                </div>
                </div>

                <div style={{marginTop:"4rem"}}>
                 <h1>Tutors</h1>
             <div style={gridContainerStyle}>
              {/* {subject === "Financial Education" ? 
              <>
              {formattedVideoSessions?.map((data, i) =>( 
              <VideosCard
              VideoData={data}
              startTimings={selectedTime.split(" - ")[0]} 
              endTimings={selectedTime.split(" - ")[1]}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              subject={subject}
              learnerID={learner?.learner._id}
              selectedTimeSlot={selectedTimeSlots}
              learner={learner?.learner}
              teacherId = {videoSession?.teacherId}
              />
              ))}
              </> */}
        {
          teachersLoaded ||
            teacherList.length > 0 ?
              teacherList.map((data, i) => (
                <>
                  <SessionCard
                    key={i}
                    teacherData={data}
                    startTimings={startTiming[i]}
                    endTimings={endTiming[i]}
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    subject={subject}
                    learnerID={learner?.learner._id}
                    classDuration={classDuration}
                    bookedTeacherId = {bookedTeacherId}
                    bookedStartTiming = {bookedStartTiming}
                    bookedEndTiming = {bookedEndTiming}
                    kidLearner = {learner?.learner?.isKid}
                  />
                </>
              ))
              :
              <p className='text-center font-inter text-sm text-neutral-800 font-bold w-screen py-8'>Sorry 😔! No teacher available at this time. </p>
        }
                </div>
                </div>
           </Card>
          
        </div>
    )

}

export default ScheduleSession;