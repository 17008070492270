import React, { useEffect, useState } from "react";
import logo from "../../assets/profile.webp"
import { Button, Image, message } from "antd";
import { UTCToAsiaTimeConverter, getDevice, UTCToAsiaTimeConverter12hr } from "../../helper/helper";
import { CreateASession } from "../../redux/actions/SessionAction";
import {localToutcTimeConverters} from "../../helper/TimeDate";

const SessionCard = ({teacherData, startTimings, endTimings, learnerID, selectedDate, subject, classDuration, bookedTeacherId, bookedStartTiming, bookedEndTiming, kidLearner}) => {

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap:"2rem"
  };

  const [startTimes, setStartTimes] = useState(UTCToAsiaTimeConverter(startTimings));
  const [endTimes, setEndTimes] = useState(UTCToAsiaTimeConverter(endTimings));
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);

  useEffect(() => {
    setStartTimes(UTCToAsiaTimeConverter(startTimings));
    setEndTimes(UTCToAsiaTimeConverter(endTimings));
  }, [startTimings, endTimings]);


  // Function to split time into slots with breaks
  const generateTimeSlots = () => {
    const timeSlots = [];

    for (let i = 0; i < startTimes.length; i++) {
      let startTime = new Date(`${selectedDate}T${startTimes[i]}`);
      let endTime = new Date(`${selectedDate}T${endTimes[i]}`);

      while (startTime < endTime) {
        const slotEndTime = new Date(startTime);
        if(classDuration==="15min"){
          slotEndTime.setMinutes(startTime.getMinutes() + 15);
        }
        if(classDuration==="25min"){
          slotEndTime.setMinutes(startTime.getMinutes() + 25);
        }
        if(subject==="IELTS"){
          slotEndTime.setMinutes(startTime.getMinutes() + 55);
          classDuration="55min"
        }

        if(kidLearner){
          slotEndTime.setMinutes(startTime.getMinutes() + 25);
          classDuration="25min"
        }

        timeSlots.push({
          start: startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          end: slotEndTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        });

        startTime = slotEndTime;

        // Add a 5-minute break
        startTime.setMinutes(startTime.getMinutes() + 5);
      }
    }

    return timeSlots;
  }

  // Book session
  const bookSession = () => {
    if (selectedSlotIndex !== null){
      const selectedTimeSlot = timeSlots[selectedSlotIndex];
    var data = {
      learner: learnerID, 
      teacher_: teacherData?._id._id, 
      startTime: localToutcTimeConverters(selectedTimeSlot?.start),
      endTime: localToutcTimeConverters(selectedTimeSlot?.end),
      sessionDate: selectedDate,
      isAdmin: true,
      isWebsite: true,
      timezone: time_zone?.timezone,
      subject:subject,
      source: getDevice(),
      sessionDuration:classDuration,
      forKids: kidLearner ? true : false,
      type: "Regular"
    }

    const onSuccess = (res) => {
      message.success(res.data.message)
      setTimeout(function(){
        window.location.reload(1);
     }, 5000);
    };
    const onError = (err) => {
      message.error(err)
    };

    CreateASession(data, onSuccess,onError)
  } else {
    message.error("Please select a time slot before booking.");
  }
  }

  // Create time slots for each pair of start and end times
    const timeSlots = generateTimeSlots()

   // Function to check if a time slot is booked
   const isTimeSlotBooked = (start, end, id) => {
    for (let i = 0; i < bookedStartTiming.length; i++) {
      if (
        UTCToAsiaTimeConverter12hr(bookedStartTiming[i]) === start &&
        UTCToAsiaTimeConverter12hr(bookedEndTiming[i]) === end &&
        bookedTeacherId[i] === id

      ) {
        return true;
      }
    }
    return false;
  };


    return (
           <div>
            <div style={{border:"1px solid #E6EAE9", borderRadius:"5px", width:"100%", padding:"1rem", background:"#F9FBF7"}}>
            <div style={{display:"flex", flexDirection:"row" , gap:"2rem", marginBottom:"2rem"}}>
            <div style={{width:"30%"}}>
            {!teacherData._id.image ? 
            <div style={{width:"100px", height:"100px"}}>
            <Image src={logo} alt="profile" width={"100%"} height={"100%"}/>
            </div>
            :
            <div style={{width:"100px", height:"100px"}}>
            <Image src={teacherData._id.image} alt="profile" width={"100%"} height={"100%"}/>
            </div>
            }
            </div>
            <div style={{width:"70%"}}>
            <h1>{teacherData._id.name}</h1>
            </div>
            </div>
            <p style={{fontWeight:"bold"}}>Available Timeslots:</p>
          <div style={gridContainerStyle}>
          {timeSlots.map((slot, i) => (
            <div>
              <button
              key={i}
              style={{
                border: "5px",
                borderRadius: "5%",
                marginBottom: "2rem",
                
              }}
              onClick={() => setSelectedSlotIndex(i)}
              className= {i === selectedSlotIndex ? "selected" : "" +
              isTimeSlotBooked(slot.start, slot.end, teacherData._id._id) ? " booked" : ""}
              disabled={isTimeSlotBooked(slot.start, slot.end, teacherData._id._id)}
              >
              
              {isTimeSlotBooked(slot.start, slot.end, teacherData._id._id) ? "Reserved" : slot.start+" - "+slot.end}
            </button>
            </div>
            ))}
          </div>
                <Button type="primary" style={{width:"100%"}} disabled={selectedSlotIndex === null} onClick={bookSession}>Book Slot</Button>
           </div>
           
           </div>
    )
}

export default SessionCard;