import api from "../apis/CurriculumApi";
import * as constant from "../constants/CurriculumConstants";

// Curriculum
export const CreateCurriculum = async (data, onSuccess, onError, dispatch) => {
  try {
    const res = await api.Curriculum().createCurriculum(data);

    onSuccess(res.data.curriculum);
    dispatch({
      type: constant.CURRICULUM_CREATED,
      payload: res.data.curriculum,
    });
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};

export const GetCurriculum = async (id, onSuccess, onError, dispatch) => {
  try {
    const res = await api.Curriculum().getCurriculum(id);

    const data = res.data;

    const formattedCurriculum = {
        _id: data.curriculum._id,
        name: data.curriculum.name,
        description: data.curriculum.description,
        modules: data.module.map((module) => {
          const associatedChapters = data.chapter.filter(
            (chapter) => chapter.module._id === module._id
          );
          let chapters = [];
          let topics = [];
          if(associatedChapters.length>0){
            chapters = associatedChapters.map((chapter) => {
              const associatedTopics = data.topic.filter(
                (topic) => topic.chapter._id === chapter._id
              );
              return {
                _id: chapter._id,
                name: chapter.name,
                description: chapter.description,
                topics: associatedTopics.map((topic) => ({
                  _id: topic._id,
                  name: topic.name,
                  description: topic.description,
                  lessonMaterial: topic.lessonMaterial,
                  type: topic.type,
                })),
              };
            })
          }
          else{
            topics = data.topic.filter(
              (topic) => topic.module._id === module._id
            );
          }
            
          return {
            _id: module._id,
            name: module.name,
            description: module.description,
            level: module.level,
            chapters,
            topics,
          };
        })
    };

    onSuccess(res.data);
    dispatch({
      type: constant.LOAD_CURRICULUM,
      payload: {formattedCurriculum,chapters:data.chapter},
    });
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};

export const GetCurriculums = async(onSuccess, onError)=>{
  try {
    const res = await api.Curriculum().getCurriculums();
    onSuccess(res.data.curriculums);
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
}

// Module
export const CreateModule = async (data, onSuccess, onError, dispatch) => {
  try {
    const res = await api.Curriculum().createModule(data);

    onSuccess(res.data);
    dispatch({
      type: constant.MODULE_CREATED,
      payload: res.data.module,
    });
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};

// Chapter
export const CreateChapter = async (data, onSuccess, onError, dispatch) => {
  try {
    const res = await api.Curriculum().createChapter(data);

    onSuccess(res.data);
    dispatch({
        type:constant.CHAPTER_CREATED,
        payload:res.data.chapter
    })
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};

// Module
export const CreateTopic = async (data, onSuccess, onError, dispatch) => {
  try {
    const res = await api.Curriculum().createTopic(data);

    onSuccess(res.data);
    dispatch({
        type:constant.TOPIC_CREATED,
        payload:res.data.topic
    })
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};

export const GetExercises = async(data, onSuccess, onError)=>{
  try {
    const res = await api.Curriculum().getExercises(data);
    onSuccess(res.data.exercises);
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
}

export const CreateExercise = async (data, onSuccess, onError, dispatch) => {
  try {
    const res = await api.Curriculum().createExercise(data);
    onSuccess(res.data);
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};

export const UpdateExercise = async (data, onSuccess, onError, dispatch) => {
  try {
    const res = await api.Curriculum().updateExercise(data);
    onSuccess(res.data);
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};

// Curriculum
export const CreateAssessment = async (data, onSuccess, onError, dispatch) => {
  try {
    const res = await api.Curriculum().createAssessment(data);
    onSuccess(res.data.assessment);
    console.log(res.data.assessment)
     dispatch({
      type: constant.ASSESSMENT_CREATED,
      payload: {
        topicId: data.topicId,
        assessment: res.data.assessment,
      },
    });
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
}

// lesson material upload
export const lessonMaterialsUpload = async (data, onSuccess, onError) => {
  try {
    const res = await api.Curriculum().lessonMaterialUpload(data);
    onSuccess(res);
    console.log(res)
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};


// topic video upload
export const topicVideoUpload = async (data, onSuccess, onError) => {
  try {
    const res = await api.Curriculum().topicsVideoUpload(data);
    onSuccess(res);
    console.log(res)
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};

// Create and Update Certificate
export const CreateCertificate = async (data, onSuccess, onError, dispatch) => {
  try {
    const res = await api.Curriculum().createCertificate(data);

    onSuccess(res.data);
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};

// get Certificates
export const GetCertificates = async(onSuccess, onError, data)=>{
  try {
    const res = await api.Curriculum().getAllCertificates(data);
    onSuccess(res.data);
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
}


// delete Certificates
export const DeleteCertificate = async(data,onSuccess, onError)=>{
  try {
    const res = await api.Curriculum().deleteCertificate(data);
    onSuccess(res.data.message);
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
}


export const UpdateTopic = async (data, onSuccess, onError, dispatch) => {
  try {
    const res = await api.Curriculum().updateTopic(data);

    onSuccess(res.data);
    dispatch({
        type:constant.TOPIC_CREATED,
        payload:res.data
    })
  } catch (error) {
    console.log(error);
    console.log(error.response);
    onError(error.response.data.message);
  }
};