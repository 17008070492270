// Menu access
export const teacherMenu = ['superadmin','admin','subadmin', 'telecaller']
export const learnerMenu = ['superadmin','admin','subadmin', 'telecaller']
export const adminAccess = ['superadmin']
export const sessionMenu = ['superadmin','admin','subadmin',"reqhandler"]
export const adminAccess2 = ['superadmin','admin','subadmin']
export const campaignMenu = ['superadmin','admin','marketing']

// Routes access
export const home = ['superadmin','admin','subadmin','marketing', 'telecaller']
export const session = ['superadmin','admin','subadmin']
export const sessionRequest = ['superadmin','admin','subadmin',"reqhandler"]
export const downloadSessionreport = ['superadmin','admin', 'telecaller']
export const createsession = ['superadmin','admin','subadmin']
export const subscribedusers = ['superadmin','admin','subadmin']
export const assignsession = ['superadmin','admin','subadmin']
export const subscriptiondetails = ['superadmin','admin','subadmin']
export const subscribedusersession = ['superadmin','admin','subadmin']
export const alllearners = ['superadmin','admin','subadmin']
export const learnerPayout = ['superadmin','admin','subadmin']
export const analytics = ['superadmin','admin']
export const coupons = ['superadmin','admin']
export const affiliate = ['superadmin','admin']
export const affiliateProfile = ['superadmin','admin']
export const allTeacher = ['superadmin','admin','subadmin']
export const teacherProfile = ['superadmin','admin','subadmin','telecaller']
export const teacherSessionAttendance = ['superadmin','admin','subadmin']
export const teacherTimeSlotsDetails = ['superadmin','admin','subadmin']
export const allLearner = ['superadmin','admin','subadmin','subadmin', 'telecaller']
export const learnerSessionAttendance = ['superadmin','admin','subadmin','telecaller']
export const upcoming = ['superadmin','admin','subadmin']
export const past = ['superadmin','admin','subadmin']
export const videoSessions = ['superadmin','admin','subadmin']
export const utmConversion = ['superadmin','admin','marketing']
export const utmCodeDetails = ['superadmin','admin','marketing']
export const sessiondoc = ['superadmin','admin','subadmin']
export const userfeedback = ['superadmin','admin','subadmin']
export const generatePaymentLink = ['superadmin','admin','subadmin']
export const teacherquery = ['superadmin','admin','subadmin']
export const planlist = ['superadmin','admin','subadmin']
export const videoApproval = ['superadmin','admin','subadmin','editor','reviewer']
export const courseAccess = ['superadmin','admin','subadmin']
export const curriculumAccess = ['superadmin','admin','subadmin']
export const demoTransactionAccess = ['superadmin','admin','subadmin']
export const disputes = ['superadmin','admin','subadmin']
export const teacherConcern = ['superadmin','admin','subadmin']
export const resourceUpload = ['superadmin','admin','subadmin']
export const userStories = ['superadmin','admin','subadmin']
export const scheduleSession = ['superadmin','admin','subadmin']
export const subscriptionList = ['superadmin','admin','subadmin','telecaller']