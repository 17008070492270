import axios from "axios";

axios.defaults.headers.common["platform"] = "AdminWeb";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Curriculum(url = `${process.env.REACT_APP_BASE_URL}`) {
        return {
            // Curriculum
            createCurriculum: (data) => axios.post(url+`/api/curriculum`,data),
            getCurriculums: () => axios.get(url+`/api/curriculum`),
            getCurriculum: (id) => axios.get(url+`/api/curriculum/admin/${id}`),

            // Module
            createModule: (data) => axios.post(url+`/api/module`,data),

            // Chapter
            createChapter: (data) => axios.post(url+`/api/chapter`,data),

            // Topic
            createTopic: (data) => axios.post(url+`/api/topic`,data),

           // Edit Topic 
           updateTopic : (data) => axios.put(url +"/api/topic",data),

           // Exercise
           getExercises: (topicId) => axios.get(url+`/api/topic/exercise?topicId=${topicId}`),
           createExercise: (data) => axios.post(url+"/api/topic/exercise", data),
           updateExercise: (data) => axios.put(url+"/api/topic/exercise", data),

            // Assessment
            createAssessment: (data) => axios.post(url+`/api/assesment/create`,data),

            // lesson material
            lessonMaterialUpload: (data) => axios.post(url+`/api/topic/learningMaterialPreSignedURL`, data),

            // topic video
            topicsVideoUpload: (data) => axios.post(url+`/api/topic/videoPreSignedURL`, data),

            // certificate
            createCertificate : (data) => axios.post(url+ `/api/assesment/createCertificate`, data),
            getAllCertificates: (data) => axios.post(url+ `/api/assesment/getAllCertificates`, data),
            deleteCertificate: (data) => axios.post(url+`/api/assesment/deleteCertificate`, data)
        }
    }
}