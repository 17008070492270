import React, { useState} from "react";
import {
  Table,
  Button,
  Space,
  Spin,
  Row,
  Col,
  Radio,
  DatePicker,
  Card,
  message,
  Select,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { UTCToAsiaTimeConverter } from "../../helper/helper";
import { GetMeetingRecording, Past } from "../../redux/actions/SessionAction";

const httpValidator = (url) =>{
  if (!/^https?:\/\//i.test(url)) {
   return'https://' + url;
  }else{
    return url
  }
}

const dateFormat = "YYYY-MM-DD";

const PastSession = () => {
  const dispatch = useDispatch();
  var pastSession = useSelector((state) => state.SessionReducer.past);
  const [date, setDate] = useState(moment(new Date()).format(dateFormat));
  // const [totalSession, setTotalSession] = useState(0);
  // const [activePage, setActivePage] = useState(1);
  const [sessionType, setSessionType] = useState("Regular");
  const [loader, setLoader] = useState(false);
  const [recordingLoader, setRecordingLoader] = useState(false);
  const [type, setType] = useState("");

  function onPickDate(date, dateString) {
    setDate(dateString);
  }
  const onChangeSessionType = (e) => {
    setSessionType(e.target.value);
  };

  const recording = (id) => {
    setRecordingLoader(id);
    const data = {
      meetingId: id,
    };
    const onSuccess = (data) => {
      setRecordingLoader(false);
      if(!data?.uuid){
        const newWindow = window.open('', '_blank');
      
        const videoHtml = `
          <html>
            <head>
              <title>Recording</title>
            </head>
            <body style="margin:0">
              <video controls autoplay style="width:100vw; height:100vh;">
                <source src="${data.recording_files[0].play_url}" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </body>
          </html>
        `;
    
        newWindow.document.write(videoHtml);
        newWindow.document.close();
        }else{
          window.open(data.recording_files[0].play_url, '_blank');
          }
    };
    const onError = (err) => {
      setRecordingLoader(false);
      message.error(err, 8);
    };
    dispatch(GetMeetingRecording(data, onSuccess, onError));
    
  };

  const fetchPastSession = () => {
    setLoader(true)
    var data = {
      date: date,
      type: sessionType,
      page: 1,
      limit: 200,
      timezone: "Asia/Kolkata",
    }
    if(type === "Curriculum") {
      data.isCurriculum = true
    }

    if(type === "Non-Curriculum") {
      data.nonCurriculum = true
    }

    if(type === "Peer Session") {
      data.isPeerSession = true
    }

    const onSuccess = () =>{
      setLoader(false)
    }

    const onError = () =>{
      setLoader(false)
    }

    dispatch(
      Past(data,onSuccess,onError)
    );
  };
  console.log("PAST", pastSession)
  const columns = [
    {
      title: "Sl No",
      dataIndex: "slNo",
      key: "slNo",
      width: 70,
    },
    sessionType === "Regular" && {
      title: "Curriculum session",
      width: 180,
      dataIndex: "curriculumSession",
      key: "curriculumSession",
      filters: [
        {
          text: "Curriculum Sessions",
          value: "true",
        },
        {
          text: "Normal Session",
          value: "false",
        },
      ],
      onFilter: (value, record) => record.curriculumSession.startsWith(value),
    },
    sessionType === "Regular" &&{
      title: "Session level",
      width: 300,
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Learner name",
      width: 150,
      dataIndex: "learnerName",
      key: "learnerName",
    },
    { title: "Teacher name",width: 150, dataIndex: "teacherName", key: "teacherName" },
    {
      title: "Type",
      width: 80,
      dataIndex: "type",
      key: "type",
      filters: [
        {
          text: "Demo",
          value: "Demo",
        },
        {
          text: "Regular",
          value: "Regular",
        },
      ],
      onFilter: (value, record) => record.type.startsWith(value),
    },
    {
      title: "User type",
      width: 100,
      dataIndex: "userType",
      key: "userType",
      filters: [
        {
          text: "Kid",
          value: "Kid",
        },
        {
          text: "Adult",
          value: "Adult",
        },
      ],
      onFilter: (value, record) => record.userType.startsWith(value),
    },
    { title: "Rating For Tutor", width: 100, dataIndex: "rating", key: "rating"},
    { title: "Date",  width: 100,dataIndex: "date", key: "date" },
    { title: "Time", width: 100, dataIndex: "time", key: "time" },
    { title: "Meeting no", width: 100, dataIndex: "meetingNo", key: "meetingNo" },
    { title: 'Teacher device',width: 200, dataIndex: 'teacherDevice', key: 'teacherDevice' },
    { title: 'Learner device', width: 200, dataIndex: 'learnerDeviceDetails', key: 'learnerDeviceDetails' },
    { title: "Host", width: 180, dataIndex: "host", key: "host" },
    {
      title: "Recording",
      key: "meetingRecording",
      fixed: "right",
      width: 130,
      render: ({ meetingNo,meetingRecording }) => (
        <>
        {meetingRecording === null?
               <a href="#" onClick={()=>recording(meetingNo)}>
                 {recordingLoader === meetingNo?"Fetching...":"Recording"}
               </a>:
        <a href={meetingRecording} target="_blank">
          Recording   
        </a>
    }
        </>
      ),
    },
  ].filter(Boolean);

  const sessionDetails = pastSession?.map((data, i) => ({
    key: i,
    slNo: i + 1,
    learnerName: data?.learner?.name,
    teacherName: data?.teacher?.name,
    type: data.type,
    userType: data.forKids ? "Kid" : "Adult",
    date: moment(new Date(data.sessionDate)).format("YYYY-MM-DD"),
    time:
      UTCToAsiaTimeConverter(data.startTime) +
      " - " +
      UTCToAsiaTimeConverter(data.endTime),
    meetingNo: data.meetingNo,
    teacherDevice:data?.teacherDeviceDetails,
    learnerDeviceDetails:data?.learnerDeviceDetails,
    rating: data?.learnerFeedback?.rating ? data?.learnerFeedback?.rating : "N/A",
    host: data.host,
    curriculumSession: data?.topic?._id?"true":"false",
    level: data?.topic?._id?
    `${data?.topic?.module?.level} level, chapter ${data?.topic?.chapter?.serialNo}, ${data?.topic?.name} `
    :"N/A",
    meetingRecording: data.meetingRecording
      ? `https://clapingo-testing.netlify.app/recordings?link=${httpValidator(data.meetingRecording)}`
      :null,
  }));

  console.log({sessionDetails})

  return (
    <div>
      <PageBreadcrumb route={["Session", "Past sessions"]} />

      <Card title="Filter Session" style={{ marginBottom: "1rem" }}>
        <Row>
          <Col span={4}>
            <Radio.Group onChange={onChangeSessionType} value={sessionType}>
              <Radio value={"Regular"}>Regular</Radio>
              <Radio value={"Demo"}>Demo</Radio>
            </Radio.Group>
          </Col>

          <Col span={20}>
            <Space>
              <DatePicker
                onChange={onPickDate}
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
              />
             {sessionType !== "Demo" && (
              <>
              <label htmlFor="sessionFilter" style={{ marginLeft:"2rem"}}>Session Filter</label>
              <Select
              defaultValue=""
              style={{ width: 120 }}
              onChange={(value) => setType(value)}
            >
               <option value="">All</option>
              <option value="Curriculum">Curriculum</option>
              <option value="Non-Curriculum">Non-Curriculum</option>
              <option value="Peer Session">Peer Session</option>
            </Select>
              </>
             )}
              <Button
                type="primary"
                onClick={() => {
                  fetchPastSession();
                }}
                loading={loader}
              >
                Search
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      {pastSession && !loader ? (
        <Table
          columns={columns}
          dataSource={sessionDetails}
          scroll={{ x: 2000 }}
          pagination={false}
        />
      ) : (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default PastSession;
