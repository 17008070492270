import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  List,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  UploadOutlined,
  VideoCameraOutlined,
  BookOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import ChapterList from "./ChapterList";
import { CreateAssessment, CreateTopic , lessonMaterialsUpload, topicVideoUpload} from "../../redux/actions/CurriculumAction";
import { useDispatch } from "react-redux";
import Assessment from "./Assesment";
import { GetPreSignedUrl } from "../../redux/actions/AuthAction";

const AddTopicDrawer = ({ curriculumId, module }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState();
  const [selectedType, setSelectedType] = useState()
  const [selectedL15File, setSelectedL15File] = useState(null);
  const [selectedL25File, setSelectedL25File] = useState(null);
  const [selectedT15File, setSelectedT15File] = useState(null);
  const [selectedT25File, setSelectedT25File] = useState(null);
  const [selectedThumb, setSelectedThumb] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [assessmentData, setAssessmentData] = useState([]);
  const [lessonMaterialURL, setLessonMaterialURL] = useState();
  const [videoURL, setVideoURL] = useState();
  const [loader, setLoader] = useState({
    learner15MinMaterial: false,
    learner25MinMaterial: false,
    tutor15MinMaterial: false,
    tutor25MinMaterial: false,
    thumbnail: false
  })
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [videoLoader, setVideoLoader] = useState(false)
  const [isVideoDisabled, setIsVideoDisabled] = useState(false);
  const [learner15MinMaterial, setLearner15MinMaterial] = useState();
  const [learner25MinMaterial, setLearner25MinMaterial] = useState();
  const [tutor15MinMaterial, setTutor15MinMaterial] = useState();
  const [tutor25MinMaterial, setTutor25MinMaterial] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [isValid, setIsValid] = useState(true);

  const handleNotesChange = (e) => {
    const fieldName = e.target.name;
  
    const isValidPdfLink = /\.pdf$/.test(e.target.value.toLowerCase());
  
    switch (fieldName) {
      case "learner15MinMaterial":
        setLearner15MinMaterial(e.target.value);
        break;
      case "learner25MinMaterial":
        setLearner25MinMaterial(e.target.value);
        break;
      case "tutor15MinMaterial":
        setTutor15MinMaterial(e.target.value);
        break;
      case "tutor25MinMaterial":
        setTutor25MinMaterial(e.target.value);
        break;
      default:
        break;
    }
  
    setIsValid(isValidPdfLink);
  };
  
  

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleAssessmentChange = (data) => {
    setAssessmentData(data);
  };


    // selected the file to upload
    const handleFileSelect = (e, learningMaterial) => {
      const file = e.target.files[0];

      if (file) {
        if(learningMaterial==="L15") setSelectedL15File(file);
        if(learningMaterial==="L25") setSelectedL25File(file);
        if(learningMaterial==="T15") setSelectedT15File(file);
        if(learningMaterial==="T25") setSelectedT25File(file);
        setIsUploadDisabled(false); 
      }
    };

    const handleThumbSelect = (e) => {
      const file = e.target.files[0];

      if(file) {
        setSelectedThumb(file);
      }
    }

    // selected the video to upload
    const handleVideoSelect = (e) => {
      const file = e.target.files[0];

      if (file) {
        setSelectedVideo(file);
        setIsVideoDisabled(false); 
      }
    };
  
  
   // Using fetch to get the presigned URL and upload the file to S3
    const handleUpload = (selectedFile, learningMaterial) => {
      setLoader((prev)=>({...prev, [learningMaterial]: true}))
        const data = {
          fileName: selectedFile?.name,
          fileType: selectedFile?.type,
        };
  
        const onSuccess = async (res) => {
          const presignedURL = res.data?.data?.presignedurl;

          console.log("logcheck", learningMaterial, res.data?.data?.img_url)

          switch (learningMaterial) {
            case "learner15MinMaterial":
              setLearner15MinMaterial(res.data?.data?.img_url);
              break;
            case "learner25MinMaterial":
              setLearner25MinMaterial(res.data?.data?.img_url);
              break;
            case "tutor15MinMaterial":
              setTutor15MinMaterial(res.data?.data?.img_url);
              break;
            case "tutor25MinMaterial":
              setTutor25MinMaterial(res.data?.data?.img_url);
              break;
            default:
              break;
          }      
  
          const response = await fetch(presignedURL, {
            method: 'PUT',
            body: selectedFile,
            headers: {
              'Content-Type': selectedFile.type,
            },
          });
  
          if (response.ok) {
            message.success('File uploaded successfully!');
            setLoader((prev)=>({...prev, [learningMaterial]: false}))
            setIsUploadDisabled(true)
          } else {
            message.error('Failed to upload file:', response.statusText);
          }
        };
  
        const onError = (errmsg) => {
          message.error(errmsg);
          setLoader((prev)=>({...prev, [learningMaterial]: false}))
        };
        lessonMaterialsUpload(data, onSuccess, onError);
      
    };


    const handleImageUpload = (selectedFile) => {
      setLoader((prev)=>({...prev, thumbnail: true}))
        const data = {
          fileName: selectedFile?.name,
          contentType: selectedFile?.type,
          bucket: "clapngobucket"
        };
  
        const onSuccess = async (res) => {
          const presignedURL = res.data?.urls;

          setThumbnail(res.data?.img_url);

          console.log("imgFile", selectedFile);
          const response = await fetch(presignedURL, {
            method: 'PUT',
            headers: {
              'Content-Type': selectedFile.type,
              'Accept': '*/*'
            },
            body: selectedFile,
          });
  
          if (response.ok) {
            message.success('File uploaded successfully!');
            setLoader((prev)=>({...prev, thumbnail: false}))
            setIsUploadDisabled(true)
          } else {
            message.error('Failed to upload file:', response.statusText);
          }
        };
  
        const onError = (errmsg) => {
          message.error(errmsg);
          setLoader((prev)=>({...prev, thumbnail: false}))
        };
        GetPreSignedUrl(data, onSuccess, onError);
    };

  

     // Using fetch to get the presigned URL and upload the file to S3
     const handleVideoUpload = () => {
      setVideoLoader(true)
        const data = {
          fileName: selectedVideo?.name,
          fileType: selectedVideo?.type,
        };
  
        const onSuccess = async (res) => {
          const presignedURL = res.data?.data?.presignedurl;
          setVideoURL(res.data?.data?.img_url)
  
          const response = await fetch(presignedURL, {
            method: 'PUT',
            body: selectedVideo,
            headers: {
              'Content-Type': selectedVideo.type,
            },
          });
  
          if (response.ok) {
            message.success('File uploaded successfully!');
            setVideoLoader(false)
            setIsVideoDisabled(true)
          } else {
            message.error('Failed to upload file:', response.statusText);
          }
        };
  
        const onError = (errmsg) => {
          message.error(errmsg);
          setVideoLoader(false)
        };
        topicVideoUpload(data, onSuccess, onError);
      
    };

  const createTopic = async () => {
    try {
      if (!isValid) {
        message.error('Please add notes links with .pdf extension.');
        return;
      }
      if(!learner15MinMaterial || !learner25MinMaterial || !tutor15MinMaterial || !tutor25MinMaterial){
        message.error('Please upload notes.');
        return;
      }
      const values = await form.validateFields();
      values["curriculumId"] = curriculumId;
      values["moduleId"] = module[0]._id;
      values["chapterId"] = selectedChapter;
      values['videoUrl'] = videoURL;
      // values["thumbnail"] = thumbnail;
      Object.assign(values, {learner15MinMaterial,learner25MinMaterial,tutor15MinMaterial,tutor25MinMaterial});

      console.log(values);

      const onSuccessCreateTopic = async (res) => {
        message.success("Topic created successfully :)");
        setOpen(false);
  
        if (selectedType === "Assessment") {
          const assessmentPayload = 
            {
              type: "Multiple Choice",
              questionsData: assessmentData,
              topicId: res.topic._id,
            }

          const onSuccessCreateAssessment = () => {
            console.log("Assessment created successfully");
          };

          const onErrorCreateAssessment = () => {
            console.log("Error creating assessment");
          };

          await CreateAssessment(
            assessmentPayload,
            onSuccessCreateAssessment,
            onErrorCreateAssessment,
            dispatch
          );
        }
      };
      const onErrorCreateTopic = (msg) => {
        message.error(msg);
      };

      CreateTopic(values, onSuccessCreateTopic, onErrorCreateTopic, dispatch);
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  console.log("module", module)

  return (
    <>
      <Button type="dashed" onClick={showDrawer} block icon={<PlusOutlined />}>
        Add Topic
      </Button>
      <Drawer
        title="Add Topic"
        width={"40%"}
        closable={false}
        onClose={onClose}
        visible={open}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label={`Serial Number`}
            name={`serialNo`}
            rules={[
              { required: true, message: "Please add a serial number!" },
              
            ]}
          >
            <Input placeholder="Serial Number" />
          </Form.Item>
          <Form.Item
            label={`Topic Title`}
            name="name"
            rules={[{ required: true, message: "Please add topic title !" }]}
          >
            <Input placeholder="Topic title" />
          </Form.Item>
          <Form.Item
            label="Topic Description"
            name="description"
            rules={[
              { required: true, message: "Please add topic description !" },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
          <Row justify="space-between">
            <Col span={11}>
              {module[0]?.level ? <Form.Item
                label="Select Chapter"
                name="chapterId"
                // rules={[
                //   { required: true, message: "Please select a chapter !" },
                // ]}
              >
                <ChapterList
                  curriculumId={curriculumId}
                  module={module}
                  setChapter={setSelectedChapter}
                />
              </Form.Item> : null}
            </Col>
            <Col span={module[0]?.level ? 11 : 24}>
              <Form.Item
                label="Select Type"
                name="type"
                rules={[{ required: true, message: "Please topic type" }]}
              >
                <Select onChange={(value) => setSelectedType(value)}>
                  <Select.Option value="Session">Session</Select.Option>
                  {module[0]?.level ? 
                    <>
                      <Select.Option value="Assessment">Assessment</Select.Option>
                      <Select.Option value="Buddy Session">
                        Buddy Session
                      </Select.Option>
                    </>
                    : 
                    <Select.Option value="Group Session">Group Session</Select.Option>
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {!module[0]?.level ? 
          <>
            {/* <div className="upload" style={{marginBottom:"0.5rem"}}>Thumbnail</div>
            <Input type="file" onChange={(e)=>{handleThumbSelect(e)}}/>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button type="primary" block onClick={() => handleImageUpload(selectedThumb)} loading={loader.thumbnail} disabled={!selectedThumb || thumbnail}>
              {loader.thumbnail? "Uploading" : "Upload"}
            </Button>
            </div> */}

            <Form.Item
            label="Thumbnail"
            name="thumbnail"
          >
              <Input placeholder="Topic thumbnail" />
            </Form.Item>
          </>
          : null}

          {selectedType === "Assessment" ? (
            <Assessment onAssessmentChange={handleAssessmentChange} />
          ) : (
            <>
            {/* <div className="upload" style={{marginBottom:"0.5rem"}}>Upload Learning Material</div>
            <Input type="file" onChange={handleFileSelect}/>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button type="primary" block onClick={() => handleUpload()} loading={loader} disabled={!selectedFile || isUploadDisabled}>
            {loader? "Uploading" : "Upload"}
            </Button>
            </div> */}

          <div className="upload" style={{marginBottom:"0.5rem"}}>Learner 15 Min Note</div>
            <Input type="file" onChange={(e)=>{handleFileSelect(e, "L15")}}/>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button type="primary" block onClick={() => handleUpload(selectedL15File, "learner15MinMaterial")} loading={loader.learner15MinMaterial} disabled={!selectedL15File || learner15MinMaterial}>
            {loader.learner15MinMaterial? "Uploading" : "Upload"}
            </Button>
          </div>

          <div className="upload" style={{marginBottom:"0.5rem"}}>Learner 25 Min Note</div>
            <Input type="file" onChange={(e)=>{handleFileSelect(e, "L25")}}/>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button type="primary" block onClick={() => handleUpload(selectedL25File, "learner25MinMaterial")} loading={loader.learner25MinMaterial} disabled={!selectedL25File || learner25MinMaterial}>
            {loader.learner25MinMaterial? "Uploading" : "Upload"}
            </Button>
          </div>

          <div className="upload" style={{marginBottom:"0.5rem"}}>Tutor 15 Min Note</div>
            <Input type="file" onChange={(e)=>{handleFileSelect(e, "T15")}}/>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button type="primary" block onClick={() => handleUpload(selectedT15File, "tutor15MinMaterial")} loading={loader.tutor15MinMaterial} disabled={!selectedT15File || tutor15MinMaterial}>
            {loader.tutor15MinMaterial? "Uploading" : "Upload"}
            </Button>
          </div>

          <div className="upload" style={{marginBottom:"0.5rem"}}>Tutor 25 Min Note</div>
            <Input type="file" onChange={(e)=>{handleFileSelect(e, "T25")}}/>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button type="primary" block onClick={() => handleUpload(selectedT25File, "tutor25MinMaterial")} loading={loader.tutor25MinMaterial} disabled={!selectedT25File || tutor25MinMaterial}>
            {loader.tutor25MinMaterial? "Uploading" : "Upload"}
            </Button>
          </div>
          
          </>
          )}
            {/* <div style={{marginBottom:"0.5rem", marginTop:"0.5rem"}}>Upload Topic Video</div>
            <Input type="file"  onChange={handleVideoSelect}/>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button type="primary" block onClick={() => handleVideoUpload()} loading={videoLoader} disabled={!selectedVideo || isVideoDisabled}>{videoLoader? "Uploading" : "Upload"}
            </Button>
          </div> */}

          <Form.Item
            label={`Topic Video`}
            name="videoUrl"
            rules={[{ required: true, message: "Please add video URl !" }]}
          >
            <Input placeholder="Topic Video URL" />
          </Form.Item>

          <div style={{marginBottom:"0.8rem", marginTop:"0.8", fontSize:"0.8rem"}}>Note: Please click on upload topic video button before adding the topic.</div>

          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button type="primary" block onClick={() => createTopic()}>
              Add Topic
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default AddTopicDrawer;
