import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Divider,
  Menu,
  Dropdown,
  Switch,
  Button,
  message,
  Spin,
  TimePicker,
  Tag,
  Modal,
  Radio,
  DatePicker,
  Space,
} from "antd";
import moment from "moment-timezone";
import { DownOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
  UpdateTeacher,
  UpdateTeacherYoutubeConsent,
} from "../../redux/actions/TeacherAction";
import {
  formatTimeSlotV2,
  localToutcTimeConverter,
} from "../../helper/TimeDate";
import AddRemoveMentee from "./AddRemoveMentee";

const { Title } = Typography;
const dateFormat = "YYYY-MM-DD";

const toggleStyle = {
  background: "#eafbff",
  padding: "10px",
  margin: "auto",
  borderRadius: "10px",
  border: "1px solid #6eacda",
};

const EditProfile = ({ teacher }) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  const [subject, setSubject] = useState("English");
  const [name, setName] = useState(teacher?.name);

  //session price
  const [english15, setEnglish15] = useState(teacher?.regular_english_15);
  const [english25, setEnglish25] = useState(teacher?.regular_english_25);
  const [kids25, setKids25] = useState(teacher?.regular_english_kids_25);
  const [ielts55, setIelts55] = useState(teacher?.regular_ielts_55);
  const [demoEnglish, setDemoEnglish] = useState(teacher?.demo_english);
  const [demoKids, setDemoKids] = useState(teacher?.demo_english_kids);
  const [demoIelts, setDemoIELTS] = useState(teacher?.demo_ielts);

  const [kidsDemo, setKidsDemo] = useState(teacher?.isKidDemo);
  const [regularDemo, setRegularDemo] = useState(teacher?.isDemo);
  const [kidsSession, setKidsSession] = useState(teacher?.isKidSession);
  const [regularSession, setRegularSession] = useState(teacher?.isSession);
  const [subjectKidsSession, setSubjectKidsSession] = useState(null);
  const [subjectRegularSession, setSubjectRegularSession] = useState(null);
  const [isSalesPerson, setIsSalesPerson] = useState(teacher?.isSalesPerson);
  const [isTwentyFiveMinBlock, setIsTwentyFiveMinBlock] = useState(
    teacher?.isTwentyFiveMinSlotsBlock
  );
  const [isFluencyCounsellor, setIsFluencyCounsellor] = useState(
    teacher?.isFluencyCounsellor
  );
  const [isFreePeerSubscriptionOffered, setIsFreePeerSubscriptionOffered] =
    useState(teacher?.isFreePeerSubscriptionOffered);
  const [curriculumAccess, setCurriculumAccess] = useState(null);
  const [peerAccess, setPeerAccess] = useState(null);
  const [peerLearningAccess, setPeerLearningAccess] = useState(null);
  const [segregatedDemoTimings, setSegregatedDemoTimings] = useState([]);
  const [segregatedRegularTimings, setSegregatedRegularTimings] =
    useState(null);
  const [timeRange, setTimeRange] = useState([]);
  const [disableSubject, setDisableSubject] = useState(null);

  const [youtubeConcent, setYoutubeConcent] = useState(
    teacher?.isYoutubeConsentSigned
  );
  const [disable, setDisable] = useState(teacher?.isDisabled);
  const [suspensionEndDate, setSuspensionEndDate] = useState(
    teacher.suspensionEndDate
  );
  const [btnLoader, setBtnLoader] = useState(false);
  const [renderSwitch, setRenderSwitch] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status, setStatus] = useState(teacher?.status || "Active");
  const [isHCT, setIsHCT] = useState(teacher?.allowAutoSwap);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [preSelectedTeachers, setPreSelectedTeachers] = useState([]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleDisableChange = (val) => {
    setDisable(val);
    if (val) {
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    // Proceed with updating the teacher profile
    // updateTeacherProfile();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDisable(false); // Reset the disable switch if the user cancels
  };

  const handleTimeChange = (time, timeString) => {
    setTimeRange(timeString);
    const slotArray = [];
    if (timeString[0] > timeString[1]) {
      message.error("start time cant be greater than end time !");
    }

    slotArray.push({
      startTime: localToutcTimeConverter(timeString[0]),
      endTime: localToutcTimeConverter(timeString[1]),
    });

    setTimeRange([]);

    const updatedValues = segregatedDemoTimings.concat(slotArray);
    setSegregatedDemoTimings(updatedValues);
  };

  const removeDemoSegregationTag = (i) => {
    segregatedDemoTimings.splice(i, 1);
  };

  useEffect(() => {
    setRenderSwitch(false);
    setSubjectKidsSession(null);
    setSubjectRegularSession(null);
    setCurriculumAccess(null);
    setPeerLearningAccess(null);
    setDisableSubject(null);
    setPeerAccess(null);

    if (teacher) {
      if (teacher?.timeSlotSegregation?.demo) {
        // const time = `${overlapUTCtimeConverter(teacher?.timeSlotSegregation?.demo?.startTime)} - ${overlapUTCtimeConverter(teacher?.timeSlotSegregation?.demo?.endTime)}`
        setSegregatedDemoTimings(teacher?.timeSlotSegregation?.demo);
      }
      if (teacher?.timeSlotSegregation?.regular) {
        const time = `${teacher?.timeSlotSegregation?.regular?.startTime} - ${teacher?.timeSlotSegregation?.regular?.endTime}`;
        setSegregatedRegularTimings(time);
      }

      if(teacher?.mentees){
        setSelectedTeachers(teacher?.mentees)
        const formattedMantees = teacher?.mentees.map(data=>{
          return{
            ...data,
            value: data.name, 
            label: data.name
          }
        })
        setPreSelectedTeachers(formattedMantees)
      }

      if (teacher.subjects.length > 0) {
        const filteredSub = teacher.subjects.filter(
          (res) => res.subject === subject
        );

        console.log({ filteredSub });
        if (filteredSub.length > 0) {
          console.log("if part");

          setSubjectKidsSession(filteredSub[0].kids ? true : false);
          setCurriculumAccess(filteredSub[0].curriculum ? true : false);
          setPeerLearningAccess(filteredSub[0].peerLearning ? true : false);
          setSubjectRegularSession(filteredSub[0].adults ? true : false);
          setDisableSubject(filteredSub[0].disabled ? true : false);
          setPeerAccess(filteredSub[0].peerLearning ? true : false);
        } else {
          console.log("else Part");
          setSubjectKidsSession(false);
          setCurriculumAccess(false);
          setPeerLearningAccess(false);
          setSubjectRegularSession(false);
          setDisableSubject(false);
          setPeerAccess(false);
        }
        setRenderSwitch(true);
      }
    }
  }, [teacher, subject]);

  const onClick = ({ key }) => {
    setSubject(key);
    setRenderSwitch(false);
  };

  const updateYoutubeConcent = (val) => {
    setYoutubeConcent(val);

    var data = {
      teacherId: id,
      enable: val,
    };
    const onSuccess = (msg) => {
      message.success(msg, 8);
    };
    const onError = (msg) => {
      message.error(msg, 8);
    };

    dispatch(UpdateTeacherYoutubeConsent(data, onSuccess, onError));
  };

  const updateTeacherProfile = () => {
    setBtnLoader(true);
    const data = {
      _id: id,
      name: name,
      isDemo: regularDemo,
      isSession: regularSession,
      isDisabled: disable,
      isKidDemo: kidsDemo,
      isKidSession: kidsSession,
      isSalesPerson: isSalesPerson,
      status: disable ? status : "Active",
      isTwentyFiveMinSlotsBlock: isTwentyFiveMinBlock,
      isFluencyCounsellor: isFluencyCounsellor,
      hct: isHCT,
      isFreePeerSubscriptionOffered: isFreePeerSubscriptionOffered,
      subject: {
        subject: subject,
        disabled: disableSubject,
        kids: subjectKidsSession,
        adults: subjectRegularSession,
        curriculum: curriculumAccess,
        peerLearning: peerAccess,
      },
      regular_english_15: english15,
      regular_english_25: english25,
      regular_english_kids_25: kids25,
      regular_ielts_55: ielts55,
      demo_english: demoEnglish,
      demo_english_kids: demoKids,
      demo_ielts: demoIelts,
    };

    var timeSlotSegregation = {};
    if (disable) {
      data.suspensionEndDate = suspensionEndDate;
    }
    if (segregatedDemoTimings) {
      timeSlotSegregation["demo"] = segregatedDemoTimings;
    }

    if (segregatedRegularTimings) {
      const splittedTime = segregatedRegularTimings.split("-");
      timeSlotSegregation["regular"] = {
        startTime: localToutcTimeConverter(splittedTime[0]),
        endTime: localToutcTimeConverter(splittedTime[1]),
      };
    }

    data["timeSlotSegregation"] = timeSlotSegregation;

    if(selectedTeachers){
      data["mentees"]=selectedTeachers
    }

    const onSuccess = (msg) => {
      setBtnLoader(false);
      message.success(msg, 8);
    };
    const onError = (msg) => {
      setBtnLoader(false);
      message.error(msg, 8);
    };

    dispatch(UpdateTeacher(data, onSuccess, onError));
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="English">English</Menu.Item>
      <Menu.Item key="IELTS">IELTS</Menu.Item>
      <Menu.Item key="Math">Math</Menu.Item>
      <Menu.Item key="Science">Science</Menu.Item>
      <Menu.Item key="History">History</Menu.Item>
    </Menu>
  );

  return (
    <div className="edit-profile">
      <Modal
        title="Select Status"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Radio.Group onChange={handleStatusChange} value={status}>
          <Radio value="Deactivate">Deactivate</Radio>
          <Radio value="Resigned">Resigned</Radio>
          <Radio value="Inactive">Inactive</Radio>
        </Radio.Group>
        {status === "Inactive" && (
          <div className="flax-div" style={{ marginTop: "1rem" }}>
            <p>Suspension End Date:</p>
            <DatePicker
              value={
                suspensionEndDate ? moment(suspensionEndDate, dateFormat) : null
              }
              onChange={(date, dateString) => setSuspensionEndDate(dateString)}
              format={dateFormat}
            />
          </div>
        )}
      </Modal>
      <Title level={5}>Edit Teacher Profile</Title>

      <Col span={24}>
        <div className="edit-name">
          <label>Edit Name :</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Teacher name"
          />
        </div>
      </Col>

      <Row gutter={[16, 24]}>
        <Col span={12}>
          <div className="flax-div" style={toggleStyle}>
            <p>Youtube Consent :</p>
            <Switch
              defaultChecked={youtubeConcent}
              onChange={(val) => updateYoutubeConcent(val)}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="flax-div" style={toggleStyle}>
            <p>Disable Teacher :</p>
            <Switch defaultChecked={disable} onChange={handleDisableChange} />
          </div>
        </Col>

        {/* {disable && (
          <Col span={24}>
            <div className="flax-div">
              <p>Suspension End Date:</p>
              <DatePicker
                value={suspensionEndDate ? moment(suspensionEndDate, dateFormat) : null}
                onChange={(date, dateString) => setSuspensionEndDate(dateString)}
                format={dateFormat}
              />
            </div>
          </Col>

        )} */}

        <Col span={12}>
          <div className="flax-div" style={toggleStyle}>
            <p>Demo Access :</p>
            <Switch
              defaultChecked={regularDemo}
              onChange={(val) => setRegularDemo(val)}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="flax-div" style={toggleStyle}>
            <p>Regular Session Access :</p>
            <Switch
              defaultChecked={regularSession}
              onChange={(val) => setRegularSession(val)}
            />
          </div>
        </Col>

        <Col span={12}>
          <div className="flax-div" style={toggleStyle}>
            <p>Kid's Demo Access :</p>
            <Switch
              defaultChecked={kidsDemo}
              onChange={(val) => setKidsDemo(val)}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="flax-div" style={toggleStyle}>
            <p>Kid's Session Access :</p>
            <Switch
              defaultChecked={kidsSession}
              onChange={(val) => setKidsSession(val)}
            />
          </div>
        </Col>
      </Row>

      <Divider plain />
      <Title level={5}>Segregate teacher timings:</Title>
      <Row>
        <Col span={6}>
          <label>Demo timings :</label>

          <TimePicker.RangePicker
            format="HH:00"
            value={timeRange}
            onChange={handleTimeChange}
          />
        </Col>
      </Row>
      <div style={{ marginTop: "0.5rem" }}>
        {segregatedDemoTimings?.map((data, i) => {
          const convertedTime = formatTimeSlotV2(
            `${data?.startTime}-${data?.endTime}`
          );
          return (
            <Tag
              color="blue"
              closable
              onClose={() => removeDemoSegregationTag(i)}
              key={i}
            >
              {convertedTime}
            </Tag>
          );
        })}
      </div>

      <Divider plain />
      <Row >
        <Col span={8}>
          <Title level={5}>Update/Add Subject Access</Title>
        </Col>
        <Col span={8} offset={8}>
          <Dropdown overlay={menu}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {subject} <DownOutlined />
            </a>
          </Dropdown>
        </Col>
      </Row>

      {renderSwitch ? (
        <>
          <Row gutter={[12,12]} >

            <Col span={8} >
              <div className="flax-div" style={{...toggleStyle}}>
                <p>Kid's {subject} Access :</p>
                <Switch
                  defaultChecked={subjectKidsSession}
                  onChange={(val) => setSubjectKidsSession(val)}
                />
              </div>
              <div className="flax-div" style={{...toggleStyle,marginTop:"12px"}}>
                <p>Fluency Counsellor</p>
                <Switch
                  checked={isFluencyCounsellor}
                  onChange={(checked) => {
                    setIsFluencyCounsellor(checked);
                    setIsTwentyFiveMinBlock(checked);
                    if (checked) setIsTwentyFiveMinBlock(true);
                    if (checked) setIsSalesPerson(false);
                  }}
                />
              </div>
              <div className="flax-div" style={{...toggleStyle,marginTop:"12px"}}>
                <p>HCT</p>
                <Switch checked={isHCT} onChange={(val) => setIsHCT(val)} />
              </div>
            </Col>
    
            <Col span={8} >
              <div className="flax-div" style={toggleStyle}>
                <p>Regular {subject} Access :</p>
                <Switch
                  defaultChecked={subjectRegularSession}
                  onChange={(val) => setSubjectRegularSession(val)}
                />
              </div>
              <div className="flax-div" style={{...toggleStyle,marginTop:"12px"}}>
                <p>Sales Person</p>
                <Switch
                  checked={isSalesPerson}
                  onChange={(checked) => {
                    setIsSalesPerson(checked);
                    if (checked) setIsFluencyCounsellor(false);
                  }}
                />
              </div>
              <div className="flax-div" style={{...toggleStyle,marginTop:"12px"}}>
                <p>Block For 25-Min Slots</p>
                <Switch
                  checked={isTwentyFiveMinBlock}
                  onChange={(val) => setIsTwentyFiveMinBlock(val)}
                />
              </div>
            </Col>
            {subject == "English" ? (
              <Col span={8}>
                <div className="flax-div" style={toggleStyle}>
                  <p>Curriculum Access :</p>
                  <Switch
                    defaultChecked={curriculumAccess}
                    onChange={(val) => setCurriculumAccess(val)}
                  />
                </div>
                <div className="flax-div" style={{...toggleStyle,marginTop:"12px"}}>
                  <p>Peer Access :</p>
                  <Switch
                    defaultChecked={peerAccess}
                    onChange={(val) => setPeerAccess(val)}
                  />
                </div>
                <div className="flax-div" style={{...toggleStyle,marginTop:"12px"}}>
                  <p>Free Peer Subscription Allowed</p>
                  <Switch
                    defaultChecked={isFreePeerSubscriptionOffered}
                    onChange={(val) => setIsFreePeerSubscriptionOffered(val)}
                  />
                </div>
              </Col>
            ) : (
              ""
            )}

            <Col span={8}>
              <div className="flax-div">
                {subject !== "English" ? (
                  <>
                    <p>Disable {subject} Access :</p>
                    <Switch
                      defaultChecked={disableSubject}
                      onChange={(val) => setDisableSubject(val)}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
          {subject == "English" ? (
            <Row>
              <Col span={4}>
                <div className="edit-name">
                  <label>English15/Session :</label>
                  <input
                    type="number"
                    value={english15}
                    onChange={(e) => setEnglish15(e.target.value)}
                    placeholder="price"
                    prefix="Rs."
                  />
                </div>
              </Col>
              <Col span={4}>
                <div className="edit-name">
                  <label>English25/Session :</label>
                  <input
                    type="number"
                    value={english25}
                    onChange={(e) => setEnglish25(e.target.value)}
                    placeholder="price"
                    prefix="Rs."
                  />
                </div>
              </Col>
              <Col span={4}>
                <div className="edit-name">
                  <label>Kids25/Session :</label>
                  <input
                    type="number"
                    value={kids25}
                    onChange={(e) => setKids25(e.target.value)}
                    placeholder="price"
                    prefix="Rs."
                  />
                </div>
              </Col>

              <Col span={4}>
                <div className="edit-name">
                  <label>EnglishDemo/Session :</label>
                  <input
                    type="number"
                    value={demoEnglish}
                    onChange={(e) => setDemoEnglish(e.target.value)}
                    placeholder="price"
                    prefix="Rs."
                  />
                </div>
              </Col>
              <Col span={4}>
                <div className="edit-name">
                  <label>KidsDemo/Session :</label>
                  <input
                    type="number"
                    value={demoKids}
                    onChange={(e) => setDemoKids(e.target.value)}
                    placeholder="price"
                    prefix="Rs."
                  />
                </div>
              </Col>
            </Row>
          ) : subject == "IELTS" ? (
            <Row>
              <Col span={4}>
                <div className="edit-name">
                  <label>DemoIELTS/Session :</label>
                  <input
                    type="number"
                    value={demoIelts}
                    onChange={(e) => setDemoIELTS(e.target.value)}
                    placeholder="price"
                    prefix="Rs."
                  />
                </div>
              </Col>
              <Col span={4}>
                <div className="edit-name">
                  <label>IELTS55/Session :</label>
                  <input
                    type="number"
                    value={ielts55}
                    onChange={(e) => setIelts55(e.target.value)}
                    placeholder="price"
                    prefix="Rs."
                  />
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      ) : (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      )}

      
        {( isFluencyCounsellor) && <>
          <Divider plain />
          <Title level={5}>Add mantes:</Title>
          <AddRemoveMentee selectedTeachers={preSelectedTeachers} setSelectedTeachers={setSelectedTeachers}/>
        </>}
      

      <Button
        type="primary"
        size="large"
        block
        loading={btnLoader}
        disabled={btnLoader}
        onClick={() => updateTeacherProfile()}
      >
        Update Access
      </Button>
    </div>
  );
};

export default EditProfile;
