import axios from "axios";

const admin = JSON.parse(localStorage.getItem("ADMIN"));

if (admin) {
  axios.defaults.headers.common["x-access-token"] = admin.token;
}
axios.defaults.headers.common["platform"] = "AdminWeb";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  UserApis(url = `${process.env.REACT_APP_BASE_URL}/api`) {
    return {
      getAllTeacher: (data) =>
        axios.post(url + "/teacher/getAllTeachersnew", data),
      SearchTeacherByNameOrPhoneno: (data) =>
        axios.post(url + "/teacher/SearchTeacherByNameOrPhoneno", data),
      getAllInfluencer: ({ status, page, limit, startDate, endDate }) =>
        axios.get(
          url +
            `/influencer/getAllInfluencers?status=${status}&page=${page}&size=${limit}&endDate=${endDate}&startDate=${startDate}`
        ),
      getInfluencerEarningsData: ({
        influencerId,
        limit,
        startDate,
        endDate,
        page,
        forKids,
        subject,
      }) =>
        axios.get(
          url +
            `/influencer/earningsData?influencerId=${influencerId}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&page=${page}`
        ),
      addAffiliateBank: (data) =>
        axios.put(url + `/influencer/addBankDetails`, data),
      getAllCoupons: (data) => axios.get(url + "/offers/getAllCoupons"),
      deleteCoupon: (data) => axios.delete(url + `/offers/deleteCouponAdmin/${data.couponId}`),
      getInfluencer: (data, params) =>
        axios.post(
          url +
            `/influencer/affiliateData?page=${params.page}&limit=${params.limit}`,
          data
        ),
      getTeacherNotes: () => axios.get(url + "/teacher/getNotes"),
      getSubscribedUser: () => axios.get(url + "/learner/getAllSubscriptions"),
      sendBookSessionNotification: (data) =>
        axios.post(url + "/learner/sendBookSessionNotification", data),
      findLearner: (data) => axios.post(url + "/learner/findLearner", data),
      getSubscriptions: (data) =>
        axios.post(url + "/learner/getSubscriptions", data),
      getUserSchedule: (data) =>
        axios.post(url + "/learner/getUserSchedule", data),
      findAllLearner: (data) => axios.post(url + "/learner/allLearner", data),
      getLearnerPayoutRequests: ({ status, page, limit }) =>
        axios.get(
          url +
            `/learner/requestPayoutAdmin?status=${status}&page=${page}&limit=${limit}`
        ),
      getAffiliatePayoutRequests: ({ status, page, limit }) =>
        axios.get(
          url +
            `/influencer/requestPayoutAdmin?status=${status}&page=${page}&limit=${limit}`
        ),
      sendLearnerPayout: (data) =>
        axios.post(url + "/learner/sendPayout", data),
      sendAffiliatePayout: (data) =>
        axios.post(url + "/influencer/sendPayout", data),
      getLearnerNotes: () => axios.post(url + "/learner/getNotes"),
      addUser: (data) =>
        axios.post(url + "/learner/signupLearnerViaAdmin", data),
      addCoupon: (data) => axios.post(url + "/offers/createCoupon", data),
      updateCoupon: (data) => axios.post(url + "/offers/updateCoupon", data),
      getPlans: () => axios.get(url + "/learner/getPlans"),
      getUTMData: (data) => {
        if (data.UTMcode) {
          return axios.get(
            url +
              `/learner/utm-data?utmCode=${data.UTMcode}&startDateUTC=&endDateUTC=`
          );
        } else {
          return axios.get(
            url +
              `/learner/utm-data?utmCode=&startDateUTC=${data.starDate}&endDateUTC=${data.endDate}`
          );
        }
      },
      getUTMConversion: (data) => {
        if (!data.startDate && !data.endDate) {
          return axios.get(
            url +
              `/learner/getconversion?utmCode=${data.UTMcode}&page=${data.page}&limit=${data.limit}&startDateUTC=&endDateUTC=`
          );
        } else {
          return axios.get(
            url +
              `/learner/getconversion?utmCode=${data.UTMcode}&page=${data.page}&limit=${data.limit}&startDateUTC=${data.startDate}&endDateUTC=${data.endDate}`
          );
        }
      },
      feedback: (data) => axios.post(url + "/rating/get-all-ratings", data),
      query: () => axios.get(url + "/query/fetch"),
      getAllApprovalRequests: ({
        status,
        page,
        limit,
        reviewer,
        editor,
        startDate,
        endDate,
      }) =>
        axios.get(
          url +
            `/ytApproval/getAllRequests?page=${page}&limit=${limit}&status=${status}&reviewer=${reviewer}&editor=${editor}&startDate=${startDate}&endDate=${endDate}`
        ),
      changeStatus: (data) =>
        axios.post(url + `/ytApproval/changeStatus`, data),
      getAllYouTubers: () => axios.get(url + `/ytApproval/getAllYouTubers`),
      getRequestBySerialNumber: (serialNumber) =>
        axios.get(
          url +
            `/ytApproval/getRequestBySerialNumber?serialNumber=${serialNumber}`
        ),
      searchYTRequestsByName: (data) =>
        axios.post(url + `/ytApproval/seacherByName`, data),
      searchLearner: (data) => axios.post(url + `/learner/findLearner`, data),
      getLearnerbyId: (data) =>
        axios.post(url + `/learner/findLearnerById`, data),
      utmConversionDetails: (data) =>
        axios.post(url + `/admingraph/utm-conversion-details`, data),
      changeInfluencerStatus: (data) =>
        axios.put(url + `/influencer/changeStatus`, data),
      sendAffiliatePay: (data) =>
        axios.post(url + `/influencer/sendAffiliatePay`, data),
      getUtmParam: (data) =>
        axios.get(url + `/admingraph/utm-parameters`, { params: data }),
      getAllVideoSessions: (data) =>
        axios.get(url + `/session/getAllVideoSessions`, { params: data }),
      getUserVideoSession: ({ learnerId, subject, isDemo }) =>
        axios.get(
          url +
            `/session/getUserVideoSession?learnerId=${learnerId}&subject=${subject}&isDemo=${isDemo}`
        ),
      searchUTMCampaigns: (data) =>
        axios.post(url + `/adminauth/searchUTMCampaigns`, data),
      addFavoriteTeacher: (data) =>
        axios.post(url + `/learner/addFavouriteSpeaker`, data),
      removeFavoriteTeacher: (data) =>
        axios.put(url + `/learner/removeFavouriteSpeaker`, data),
      getFavoriteTeachers: ({learnerId}) => axios.get(url + `/learner/getFavouriteSpeakers?learnerId=${learnerId}`),
      deleteLearnerAccount: (data) => axios.post(url + `/learner/deleteLearnerAccount`,data),
      resetPauseLimit: (data) => axios.put(url + `/learner/resetPauseLimit`,data),
    };
  },
};
